import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";

const Brands = ({logos, bg = 'bg-slate-100'}) => {

  return (
    <section className={clsx('py-20', bg)}>
      <div className="xl:container mx-auto px-6">
        <div className="text-center mb-12">
          <h3 className="text-3xl md:text-4xl font-black">Some of the brands we <span className="text-sourci-pink">work</span> with.</h3>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-x-12 xl:gap-x-36 gap-y-10 items-center">
          {logos.map((i,k) => (
            <div className="items-center justify-center flex"
              key={k}>
              <GatsbyImage image={getImage(i.logo)}
                alt={i.logo.alt || ''}
                className="mx-auto xl:max-w-[200px]" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Brands;