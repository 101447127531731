import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../components/Seo";
import Brands from "../components/Brands";
import Cta from "../components/Cta";
import Carousel from "../components/Projects/Carousel";

const getItemStyle = ({
  text_size,
  bold_text,
  new_line
}) => {
  switch (text_size) {
    case 'Heading':
      return clsx({
        'font-black': bold_text,
        'block mb-8': new_line
      }, 'text-5xl lg:text-6xl')
    case 'Subheading':
      return clsx({
        'font-black': bold_text,
        'block mb-8': new_line
      }, 'text-3xl lg:text-4xl')
    case 'Caption': 
      return clsx({
        'font-bold': bold_text,
        'block': new_line
      }, 'text-2xl lg:text-3xl')
    case 'Paragraph':
    default:

      return clsx({
        'font-bold': bold_text,
        'block mt-8 mb-8': new_line
      }, 'text-xl lg:text-2xl')
  }
}

const CategoryTemplate = ({pageContext}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  const data = pageContext.data.node.data;
  textRef.current = data.body.map((_, i) => textRef.current[i] ?? createRef());
  imageRef.current = data.body.map((_, i) => imageRef.current[i] ?? createRef());

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const listStyle = "block list-marker before:inline-block before:w-[1em] before:text-[1.7rem] before:text-sourci-teal !mb-0";
  return (
    <main>
      {data.body.map((section, k) => (
        <section className="py-24"
          key={k}
          style={{backgroundColor: section.primary.background_color}}>
            <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-start gap-x-12 gap-y-12">
              <div ref={textRef.current[k]}
                className={clsx(section.primary.image_position === 'Left' ? 'order-1 lg:order-2' : 'order-1')}>
                {k === 0 && <h1 className="font-black text-5xl lg:text-6xl mb-12"
                    style={{color: data.category_color}}>{data.category_title}</h1>}
                {section.items.map((i,k) => (
                  <React.Fragment key={k}>
                    <span className={clsx(getItemStyle({...i}), i.list_item && listStyle)}
                      style={{color: i.text_color}}> {i.text.text} </span>
                    {i.divider && <hr className={clsx('w-[calc(100%+3rem)] border mb-8', section.primary.image_position === 'Left' && '-ml-12')} />}
                  </React.Fragment>
                ))}
              </div>
              <div className={clsx('flex h-full', section.primary.image_position === 'Left' ? 'order-1' : 'order-1 lg:order-2')}
                ref={imageRef.current[k]}>
                  <GatsbyImage image={getImage(section.primary.section_image)}
                    alt={section.primary.section_image.alt || ''}
                    className="object-cover min-w-[100%] rounded-[55px]" />
              </div>
            </div>
        </section>
      ))}
      {data.cta_paragraph > 0 &&
        <Cta bg="sourci-teal"
          image={data.cta_background_image}>
          <div className="text-center max-w-[700px] mx-auto">
            <p className="text-5xl md:text-6xl font-black mb-12">
            {data.cta_title_parts.map((i,k) => (
              <span className={clsx(i.new_line && 'block')}
                style={{color: i.color}}
                key={k}> {i.text} </span>
            ))}
            </p>
            <p className="text-xl lg:text-2xl text-white">{data.cta_paragraph.text}</p>
          </div>
        </Cta>
      }
      <Brands logos={pageContext.data.brands}
        bg="bg-[#f1f2f2]" />
      <Carousel projects={pageContext.data.projects}
        title="Check out some of our projects!">
      </Carousel>
    </main>
  )
}

export default CategoryTemplate;

export function Head({pageContext}) {
  return (
    <Seo title={pageContext.data.node.data.meta_title}
      description={pageContext.data.node.data.meta_description}
      canonical={`/services/${pageContext.data.node.uid}`} />
  )
}